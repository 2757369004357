import React from "react" // eslint-disable-line no-unused-vars
import { useStaticQuery, graphql } from "gatsby"

import Blockquote from "../../../components/blockquote"
import Breadcrumb from "../../../components/breadcrumb"
import Constrain from "../../../components/constrain"
import Paragraph from "../../../components/paragraph"
import Heading from "../../../components/heading"
import HoelderlinLiebtNavigation from "../../../content/hoelderlin-liebt/navigation"
import Image from "../../../components/image"
import Layout from "../../../layouts/default"
import PageTitle from "../../../components/page-title"
import PaperStack from "../../../components/paper-stack"
import Seo from "../../../components/seo"
import SectionSpacing from "../../../components/section-spacing"
import Stack from "../../../components/stack"
import Column from "../../../components/column"
import Columns from "../../../components/columns"
import VideoPlayer from "../../../components/video-player"

const HoelderlinLiebtTurmPage = () => {
  const data = useStaticQuery(
    graphql`
      {
        ogImage: file(relativePath: { eq: "og_images/hoelderlin-liebt.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(width: 1200, height: 600, layout: FIXED)
          }
        }
        lotte: file(relativePath: { eq: "hoelderlin-liebt/lotte-zimmer.jpg" }) {
          ...smallImage
        }
        ernst: file(relativePath: { eq: "hoelderlin-liebt/ernst-zimmer.jpg" }) {
          ...smallImage
        }
        berichtZimmer: file(
          relativePath: {
            eq: "dauerausstellung/01-die-linien-des-lebens/bericht-zimmer.jpg"
          }
        ) {
          ...largeImage
        }
        posterLinien: file(
          relativePath: { eq: "hoelderlin-liebt/poster-linien-des-lebens.jpg" }
        ) {
          ...largeImage
        }
        posterTurm: file(
          relativePath: { eq: "hoelderlin-liebt/poster-turm.jpg" }
        ) {
          ...largeImage
        }
      }
    `
  )

  return (
    <Layout backdrop="hoelderlin-liebt">
      <Seo
        title="Hölderlin wohnt in einem Turm"
        description="Die letzten 36 Jahre seines Lebens verbringt Hölderlin bei der Familie Zimmer in Tübingen."
        image={data.ogImage.childImageSharp.gatsbyImageData}
      />
      <SectionSpacing>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Digital",
                link: "/digital",
              },
              {
                title: "Sonderausstellungen",
                link: "/sonderausstellungen",
              },
              {
                title: "Hölderlin liebt",
                link: "/sonderausstellungen/hoelderlin-liebt",
              },
              {
                title: "Hölderlin wohnt in einem Turm",
                link: "/sonderausstellungen/hoelderlin-liebt/turm",
              },
            ]}
          />
          <PageTitle>Hölderlin wohnt in einem Turm</PageTitle>
          <Paragraph dropcap={true}>
            Die letzten 36 Jahre seines Lebens <br />
            verbringt Hölderlin bei der Familie Zimmer in Tübingen. <br />
            Vom Jahr 1806 bis zu seinem Tod im Jahr 1843. <br />
            Die Familie Zimmer hat ein Haus mit einem Turm. <br />
            Es liegt direkt am Neckar. <br />
          </Paragraph>
          <Columns collapse={[true, false]} space={12}>
            <Column width={["1/2", "1/3"]}>
              <Image
                image={data.ernst.childImageSharp.gatsbyImageData}
                alt="Eine Illustration zeigt Ernst Zimmer mit einem Handwerker-Hut."
              />
            </Column>
            <Column>
              <Stack>
                <Paragraph>
                  Ernst Zimmer ist Schreiner. <br />
                  Er macht Dinge aus Holz. <br />
                  Und er mag die Gedichte von Hölderlin.
                </Paragraph>
                <Paragraph>
                  Als Hölderlin krank wird <br />
                  und nicht mehr alleine leben kann <br />
                  lädt ihn der Schreiner Ernst Zimmer <br />
                  zu sich nach Hause ein. <br />
                  Nicht bloß für einen Tag. Sondern für immer.
                </Paragraph>
              </Stack>
            </Column>
          </Columns>
          <Columns collapse={[true, false]} space={12}>
            <Column width={["1/2", "1/3"]}>
              <Image
                image={data.lotte.childImageSharp.gatsbyImageData}
                alt="Eine Illustration zeigt Lotte Zimmer. Sie hat lange Haare und neigt den Kopf zur Seite."
              />
            </Column>
            <Column>
              <Paragraph>
                Hölderlin bekommt ein Zimmer im Turm. <br />
                Als Ernst Zimmers jüngste Tochter Lotte geboren wird,
                <br />
                wohnt Hölderlin schon seit 6 Jahren im Haus der Familie Zimmer.{" "}
                <br />
                Lotte kümmert sich schon als Kind um den Dichter. <br />
                Die beiden werden Freunde. <br />
                In ihren Armen wird er 1843 im Alter von 73 Jahren sterben.
              </Paragraph>
            </Column>
          </Columns>
        </Stack>

        <VideoPlayer src="hoelderlin-liebt/turm" poster={data.posterTurm} />

        <Stack>
          <Heading as="h2" level={3}>
            Die Linien des Lebens
          </Heading>
          <Paragraph>
            Hölderlins Gedicht ›Die Linien des Lebens‹ <br />
            ist in der Werkstatt von Ernst Zimmer entstanden. <br />
            Darüber schreibt Ernst Zimmer im Jahr 1812 an Hölderlins Mutter:
          </Paragraph>
          <PaperStack>
            <Stack>
              <Paragraph>
                Hochgeehrteste! <br />
                Ihr lieber Sohn schreibt immer noch Gedichte. <br />
                Neulich war er bei mir in der Werkstatt. <br />
                Dort liegt schon lange eine Zeichnung, auf der ein Tempel zu
                sehen ist. <br />
                Ihr Sohn hat mich gefragt, ob ich diesen Tempel aus Holz bauen
                kann. <br />
              </Paragraph>
              <Paragraph>
                Ich musste lachen: <br />
                Ach, Hölderlin, ein Schreiner baut Tische und Stühle, Türen und
                Treppen. <br />
                Ich habe keine Zeit, nur so aus Spaß einen kleinen Tempel zu
                machen. <br />
                Da hat er mich traurig angeschaut und mit einem Stift auf eines
                der Bretter geschrieben:
              </Paragraph>
              <Blockquote as="blockquote">
                <Paragraph>
                  Die Linien des Lebens sind verschieden <br />
                  Wie Wege sind, und wie der Berge Grenzen. <br />
                  Was hier wir sind, kann dort ein Gott ergänzen <br />
                  Mit Harmonien und ewigem Lohn und Frieden.
                </Paragraph>
              </Blockquote>
            </Stack>
          </PaperStack>
          <Constrain align="right">
            <Image
              image={data.berichtZimmer.childImageSharp.gatsbyImageData}
              caption="Der Brief von Ernst Zimmer"
              alt="Ein handschriftlicher Bericht von Ernst Zimmer an Hölderlins Mutter"
            />
          </Constrain>
        </Stack>

        <Stack>
          <Heading as="h2" level={3}>
            Hölderlins Gedicht ›Die Linien des Lebens‹ in Gebärdensprache
          </Heading>
          <VideoPlayer
            src="hoelderlin-liebt/linien-des-lebens"
            poster={data.posterLinien}
          />
        </Stack>

        <HoelderlinLiebtNavigation />
      </SectionSpacing>
    </Layout>
  )
}

export default HoelderlinLiebtTurmPage
